<!DOCTYPE html>
<html lang="fr">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Ordonnance</title>
    <style>
        body {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            height: 100vh;
            font-family: Arial, sans-serif;
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            max-width: 148mm;
            max-height: 210mm;
            margin: 0 auto;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
            box-sizing: border-box;
        }

        header,
        footer {
            padding: 10px;
            text-align: center;
            font-size: 12px;
        }
        footer *{
            font-size: 12px;
        }

        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .header-content div {
            flex: 1;
            padding: 0 5px;
            text-align: left;
        }

        .header-content .logo {
            text-align: center;
        }

        .logo {
            max-width: 150px;
        }


        .bold-line {
            border-top: 3px solid #bd6127;
        }
        .contenu{
            font-size: 14px;
        }
        .text-center{
            font-size: 20px;
        }
    </style>
</head>

<body>
    <div class="container">
        <!-- En-tête -->
        <header>
            <div class="header-content">
                <div>
                    <p class="m-0"><strong>Dr.OMAR BEN HADJ SAÏD</strong></p>
                    <hr class="my-1 bold-line">
                    <p class="m-0"><strong class="letter-spacing">OPHTALMOLOGISTE</strong></p>
                    <p class="m-0">Chirurgie de la cataracte</p>
                    <p class="m-0">Traitement aux Lasers,LASIK</p>
                    <p class="m-0">Prise en charge du kératocône</p>
                </div>
                <div class="logo">
                    <img src="../../../assets/Logo - fev 2024.png" alt="Logo" class="logo">
                    <p><strong> Code CNAM:1/28144/14</strong></p>
                </div>
                <div style="text-align: right;">
                    <p class="m-0"> <strong> الدكتور عمر بن حاج سعيد</strong></p>
                    <hr class="my-1 bold-line">
                    <p class="m-0"><strong>أخصائي طب و جراحة العيون و العلاج بالليزر</strong></p>
                    <p class="m-0">جراحة العدسة</p>
                    <p class="m-0">تصحيح النظر بالليزر</p>
                    <p class="m-0">علاج القرنية المخروطية</p>
                </div>
            </div>
        </header>

        <!-- CERTIFICAT MEDICAL -->
        <div class="title">
            <h1 class="text-center" style="color: #bd6127;"><strong>CERTIFICAT MÉDICAL</strong></h1>
        </div>
        <div class="main-content">
            <div class="col">
                <p class="text-end contenu">Tunis le 22/05/2024</p>
                <p class="contenu">Je soussigné <strong>Docteur Omar Ben Haj Said </strong> certifie que l'état de santé
                    de :</p>
                <p class="contenu">Mme/Mr <strong> Foulen Ben Foulen</strong> nécessite un arrêt de travail de 03 jours
                    à dater du 22/05/2024 sauf complications.</p>
            </div>
        </div>

        <!-- Ordonnance-->
        <!-- <div class="title">
            <h1 class="text-center" style="color: #bd6127;"><strong>ORDONNANCE</strong></h1>
        </div>
        <div class="main-content">
            <div class="col">
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            <h5 class="text-left m-0"><strong>Nom de médicament</strong></h5>
            <p class="m-0">3/j 15j </p>
            </div>
        </div> -->

        <!-- CERTIFICAT D'ADAPTATION A LA CONDUITE-->
        <!-- <div class="title">
            <h1 class="text-center" style="color: #bd6127;"><strong>CERTIFICAT D'ADAPTATION A LA CONDUITE</strong></h1>
        </div>
        <div class="main-content">
            <div class="col">
                <p class="contenu m-0">Tunis le 22/05/2024</p>
                <p class="contenu m-0">Je soussigné <strong>Docteur Omar Ben Haj Said </strong> certifie certifie avoir examiner</p>
                <p class="contenu m-0">Mme/Mr <strong>Foulen Ben Foulen</strong> et que son examen ophtalmologique montre :</p>
                <p class="contenu m-0">Acuité visiuelle avec correction:</p>
                <p class="contenu m-0"> *Oeil droit: Acuité visiuelle sans correction
                    .................................................</p>
                <p class="contenu m-0"> &thinsp; &thinsp; &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp;
                    &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp; &thinsp;&thinsp;&thinsp;
                    Acuité visiuelle sans correction ..................................................................
                </p>
                <p class="contenu m-0"> *Oeil droit: Acuité visiuelle sans correction
                    ..................................................................</p>
                <p class="contenu m-0"> &thinsp; &thinsp; &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp;
                    &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp; &thinsp;&thinsp;&thinsp;
                    Acuité visiuelle sans correction ..................................................................
                </p>
                <p class="contenu m-0">Par concéuence Il/Elle est apte/Inapte à conduire tous type de véhicule</p>
                <p class="text-right contenu m-0">Cordialement</p>
            </div>
        </div> -->



        <!-- Bas de page -->
        <footer>
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <hr class="my-1 bold-line col-10 text-center mx-auto">
                    <p class="m-0"><strong>شارع الحبيب بورقيبة باردو-مركز مدرادا الطابق الرابع مكتب عدد 19 قبالة
                            مونوبري</strong></p>
                    <p class="m-0">8,Avenu Habib Bourgiba - Bardo - Centre Medrara, 4<sup>ème</sup> Étage App 19 (En
                        face de Monoprix)</p>
                    <p class="m-0"><i class="fas fa-phone-alt" style="color: #bd6127;"></i> +216 71 507 753 <i
                            class="fas fa-mobile-alt" style="color: #bd6127;"></i> +216 55 180 065 <i
                            class="fas fa-envelope" style="color: #bd6127;"></i>
                        omarbenhajsaid@gmail.com</p>
                </div>
            </div>
        </footer>
    </div>
    <div class="text-center mt-4 no-print">
        <button class="btn btn-primary mr-2">Imprimer PDF</button>
        <button class="btn btn-secondary">Prévisualiser PDF</button>
    </div>
</body>

</html>