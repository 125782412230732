import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import countries from 'src/app/services/constants/List_countries.json';
import postCode from 'src/app/services/constants/TN_Postal_codes.json';
import { PatientService } from 'src/app/services/patient/patient.service';
import { Router } from '@angular/router';
import { apciMaxElements, champMaxLength, champMinLength, champsObligatoire, invalidEmailAddress, invalidNumTelephone } from 'src/app/services/constants/messages';
import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ProfessionService } from 'src/app/services/professions/profession.service';


@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})
export class AddPatientComponent implements OnInit {
  defaultPays = "Tunisie"
  registerForm: FormGroup;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme', 'Femme']
  statusFamiliales = ['Célibataire', 'Marié(e)', 'Divorcé(e)', 'Veuf(ve)']
  groupeSanguins = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']
  list_contries = countries
  postal_codes = postCode
  defaultCity = "Tunisie"

  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  typeOrgs = ['CNAM', 'CNSS']
  governorates: any[];
  delegs: any[];
  cites: any
  codePostalChoise: any = ""
  today = new Date()
  medecinId: any;
  // message d'erreur
  champsObligatoire = champsObligatoire
  champMinLength = champMinLength
  champMaxLength = champMaxLength
  invalidEmailAddress = invalidEmailAddress
  invalidNumTelephone = invalidNumTelephone
  apciMaxElements = apciMaxElements
  // end message d'erreur
  codePostalList: any = []
  age: any
  medecin: any
  medecinUsedId: any
  patients: any = []

  filteredProfessions: string[] = [];
  professionInput: string = '';

  professions: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private router: Router,
    private consultationService: ConsultationService,
    private authService: AuthService,
    private toastService: ToastService,
    private professionService: ProfessionService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.setupGovernorates();
    this.setupDelegs();
    this.loadMedecinProfile();
    this.getAllProfessions();
  }

  private initializeForm(): void {
    this.registerForm = this.formBuilder.group({
      numFiche: ['', [Validators.required, Validators.maxLength(25)]],
      nom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      prenom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      dateAnniversaire: ['', Validators.required],
      dateDeces: [''],
      email: ['', Validators.email],
      tel: ['', Validators.required],
      telsec: [''],
      profession: [''],
      groupeSanguin: [''],
      sexe: ['', Validators.required],
      statusFamilial: [''],
      ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      pays: [''],
      codePostal: ['', [Validators.maxLength(4)]],
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
        pays: [''],
        codePostal: ['', [Validators.maxLength(4)]],
      }),
      apci: [[]],
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        codeCNAM: [''],
        codeCNSS: [''],
        nomEtablissement: ['', [Validators.maxLength(25)]],
        email: [''],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25)]],
          codePostal: ['', [Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
      notes: ['']
    });
  }

  // Setup Governorates
  private setupGovernorates(): void {
    this.governorates = [...new Set(this.postal_codes.map(item => item.Gov))];
  }

  // Setup Delegs for "Tunis"
  private setupDelegs(): void {
    const listdelegs = this.postal_codes.filter(x => x.Gov === "Tunis");
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    this.delegs = [...new Set(delegsNames)];
  }

  // Load Medecin Profile from Local Storage
  private loadMedecinProfile(): void {
    if (localStorage.getItem('medecin')) {
      this.medecinId = JSON.parse(localStorage.getItem('medecin')).id;
      this.fetchMedecinProfile();
    }
  }

  // Fetch Medecin Profile from API
  private fetchMedecinProfile(): void {
    this.authService.getProfileMedecin().subscribe(
      (profile: any) => {
        this.medecin = profile.medecin;
        this.medecinUsedId = this.medecin._id;
        this.getPatients();
        if (!this.medecin) {
          this.router.navigate(['/medecin/accueil']);
        }
      },
      err => {
        this.router.navigate(['/medecin/accueil']);
      }
    );
  }

  getAllProfessions() {
    this.professionService.getAllProfessions()
    .subscribe((professions: any[]) => {
      this.professions = professions;
    },
    err => {
     
      return false;
    })
  }

  addProfession(professionTitle: string): void {
    this.professionService.createProfession({ title: professionTitle })
    .subscribe((newProfession) => {
      this.professions.push(newProfession)
      
    },
    (err) => {
      
    })
  }

  get f() { return this.registerForm.controls; }

  calculateAge(): void {
    const birthDate = new Date(this.registerForm.value.dateAnniversaire);
    const deathDate = this.registerForm.value.dateDeces ? new Date(this.registerForm.value.dateDeces) : null;
    
    // Check if the dateDeces is earlier than dateAnniversaire
    if (deathDate && deathDate < birthDate) {
      this.toastService.showError('La date de décès ne peut pas être inférieure à la date de naissance.');
      this.registerForm.controls['dateDeces'].setErrors({ incorrect: true }); // Mark as invalid
      return;
    }
  
    let calculationDate = this.today;
    if (deathDate) {
      calculationDate = deathDate;
    }
  
    this.age = calculationDate.getFullYear() - birthDate.getFullYear();
    const isBeforeBirthday =
      calculationDate.getMonth() < birthDate.getMonth() ||
      (calculationDate.getMonth() === birthDate.getMonth() &&
        calculationDate.getDate() < birthDate.getDate());
  
    if (isBeforeBirthday) {
      this.age--;
    }
  }

  registerPatient(action: string) {
    this.consultationService.registerPatient(this.registerForm.value).subscribe(
      (patient: any) => {
        if (patient && patient._id) {
          this.toastService.showSuccess('Bien ajouté');
          if (action === 'save') {
            this.resetForm();
          } else if (action === 'consult') {
            this.router.navigate(['/medecin/consultation-liste', patient._id]);
          }
        } else {
          this.handleFailure(action);
        }
      },
      (error) => {
        this.handleFailure(action);
      }
    );
  }
  
  handleFailure(action: string) {
    setTimeout(() => {
      if (action === 'consult') {
        this.router.navigate(['/medecin/patients-liste']);
      } else if (action === 'save') {
        this.resetForm();
      }
    }, 2000);
  }
  

  onSubmit(action: string) {
    this.submitted = true;
  
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.dateDeces && new Date(this.registerForm.value.dateDeces) < new Date(this.registerForm.value.dateAnniversaire)) {
      this.toastService.showError('La date de décès ne peut pas être antérieure à la date de naissance.');
      return;
    }
  
    if (this.registerForm.value.tel) {
      this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber;
    }
    if (this.registerForm.value.telsec) {
      this.registerForm.value.telsec = this.registerForm.value.telsec.internationalNumber;
    }
  
    this.calculateAge();
  
    this.registerForm.value.listMedecinsId = this.medecinId;
    this.registerForm.value.createdByDoctor = true;
    this.registerForm.value.age = this.age;
    this.registerForm.value.expiredAt = null;
  
    this.registerPatient(action);
  }
  

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    this.professionInput = inputValue;
  
    // Filter the professions for the dropdown based on the input
    this.filteredProfessions = this.professions
      .map((profession) => profession.title)
      .filter((title) =>
        title.toLowerCase().includes(inputValue.toLowerCase())
      );
  }
  
  onBlur(): void {
    const inputValue = this.professionInput.trim();
  
    // Check if inputValue is not in predefined professions
    if (
      inputValue &&
      !this.professions.some((profession) => profession.title === inputValue)
    ) {
      // Call CRUD service to add a new profession
      this.addProfession(inputValue);
    }
  }

  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        this.patients = this.patients.filter(el => el.createdByDoctor == true)
      });
  }

  resetForm() {
    this.registerForm.reset();
    this.submitted = false;
  }

  getPays() {
    this.registerForm.get('adresse.ville').patchValue('');
    this.registerForm.get('adresse.codePostal').patchValue('');
    this.registerForm.get('adresse.adresse').patchValue('');
  }

  getVille(cov) {
    this.registerForm.get('adresse.codePostal').patchValue('');
    let listdelegs = this.postal_codes.filter(x => x.Gov === cov)
    // Extraction des noms des délégations
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    // Élimination des doublons en utilisant un Set
    this.delegs = [...new Set(delegsNames)];
    this.codePostalList = [...new Set(listdelegs.map(deleg => deleg.zip))]
  }
}
