export const API_CONFIG = {
    // apiPathBase: 'http://localhost:3012/',
    apiPathBase: '/',
    patientByEmail: 'api/patientByEmail',
    register: 'api/register',
    profile: 'api/profile',
    authenticate: 'api/authenticate',
    authenticateMedecin: 'api/authenticate/medecin',
    checkValidation: 'api/check/validation/auth/medecin',
    authenticateSecretaire: 'api/authenticate/secretaire',
    profilMedecin: 'api/profilMedecin',
    profilSecretaire: 'api/profilSecretaire',
    certificat: 'api/certificat',
    consultation: 'api/consultation',
    medecin: 'api/medecin',
    medecinScraped: 'api/medecinScraped',
    ordonnance: 'api/ordonnance',
    patients: 'api/patients',
    patient: 'api/patient',
    rdvs: 'api/rdvs',
    secretaire: 'api/secretaire',
    agenda: 'api/agenda',
    upload: 'api/upload',
    document: 'api/document',
    event: 'api/event',
    pays: 'api/pays',
    image: 'api/image',
    message: 'api/message',
    medicament: 'api/medicament',
    notification: 'api/notification',
    recaptchaverification: 'api/verify-recaptcha',
    uploadArticle: 'data/files/articles',
    examen: 'api/examen',
    anomalie: 'api/anomalie',
    connectRestApi: 'connect/rest/api',
    medecinPatient: 'api/medecinsPatients',
    professions: 'api/professions'

};
export function _getURL(serviceName) {
    return API_CONFIG.apiPathBase + serviceName;
}


export const redirectNotAuthenticated = 'http://www.website.medivisto.com/connexion'
