import { Ng2IzitoastService } from 'ng2-izitoast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MotifConsultationComponent } from '../popups/motif-consultation/motif-consultation.component';
import { HistoriqueConsultationComponent } from '../popups/historique-consultation/historique-consultation.component';
import { SuppressionComponent } from '../popups/suppression/suppression.component';
import { NgSelectConfig } from '@ng-select/ng-select';
import { PatientService } from '../services/patient/patient.service';
import { AuthService } from '../services/auth/auth.service';
import { ConsultationService } from '../services/consultation/consultation.service';
import { MedecinService } from '../services/medecin/medecin.service';
import * as XLSX from 'xlsx';
import { PopupSearchPatientComponent } from '../popups/search-patient/popup-search-patient.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
  providers: [NgbActiveModal]
})
export class PatientComponent implements OnInit {
  page: number = 1;
  patients: any[] = [];
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;
  copyListPatients: any[];

  prenomToFiltred: any;
  nomToFiltred: any;
  professionToFiltred: any;
  numFicheToFiltred: any;
  ageToFiltred: any;
  medecinId: string | null;
  selectedPatient: any;
  patient: any
  medecin: any
  medecinUsedId: any
  secretaire: any

  loading: boolean = false;

  sortColumn: string = '';
  sortOrder: string = 'asc';
  sortAscending: boolean = true; 

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private config: NgSelectConfig,
    private patientService: PatientService,
    private authService: AuthService,
    private iziToast: Ng2IzitoastService,
    private consultationService: ConsultationService,
    private medecinService: MedecinService
  ) {
    this.config.notFoundText = "Aucun élément trouvé"
  }


  ngOnInit(): void {
    if (localStorage.getItem('medecin')) {
      this.handleMedecinProfile();
    } else if (localStorage.getItem('secretaire')) {
      this.handleSecretaireProfile();
    }
  }
  
  private handleMedecinProfile(): void {
    this.loading = true;
    this.authService.getProfileMedecin().subscribe(
      (profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return;
        }
        this.medecinUsedId = this.medecin._id;
        this.loadPatientsAndSort();
      },
      err => {
        this.loading = false;
        // Handle error here if needed
      }
    );
  }
  
  private handleSecretaireProfile(): void {
    this.loading = true;
    this.authService.getProfileSecretaire().subscribe(
      (profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return;
        }
        this.medecinUsedId = this.secretaire.medecinId;
        this.loadPatientsAndSort();
      },
      err => {
        this.loading = false;
        // Handle error here if needed
      }
    );
  }
  
  private loadPatientsAndSort(): void {
    this.getPatients();
    this.sortByColumn('numFiche');
  }

  getPatients() {
    this.loading = true;
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        if (this.patients) {

          this.patients = patients.sort(this.triapatients);

          this.copyListPatients = this.patients
          this.loading = false;
        }
      },
      err => {
        console.error('Error loading patients', err);
        this.loading = false;
        return false;
      });
  }

  onTableDataChange(event: any) {
    this.page = event;

  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  calculateAge(dateOfBirth: string, dateDeces?: string): number {
    const birthDate = new Date(dateOfBirth);
    let endDate: Date;
  
    // If dateDeces is provided and is not empty, use it for the calculation
    if (dateDeces && dateDeces.trim() !== "") {
      endDate = new Date(dateDeces);  // Use the provided date of death
    } else {
      endDate = new Date();  // Use the current date if no dateDeces is provided
    }
  
    // Calculate the age
    let age = endDate.getFullYear() - birthDate.getFullYear();
    const m = endDate.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birthdate hasn't occurred yet this year
    if (m < 0 || (m === 0 && endDate.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }  

  // Function to sort by age
  sortByColumn(column: string) {
    if (this.sortColumn === column) {
      // Toggle sorting order if the same column is clicked again
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // If a new column is clicked, sort in ascending order
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }

    // Sort patients based on the selected column and order
    if (this.sortColumn === 'age') {
      this.patients.sort((a, b) => {
        // Pass both dateAnniversaire and dateDeces (or undefined if it's null) to calculateAge
        const ageA = this.calculateAge(a.dateAnniversaire, a.dateDeces);
        const ageB = this.calculateAge(b.dateAnniversaire, b.dateDeces);
        return this.sortOrder === 'asc' ? ageA - ageB : ageB - ageA;
      });
    } else {
      this.patients.sort((a, b) => {
        const valueA = a[this.sortColumn];
        const valueB = b[this.sortColumn];
        return this.sortOrder === 'asc' ? (valueA < valueB ? -1 : 1) : (valueA > valueB ? -1 : 1);
      });
    }
}


  deletPatient(patient) {
    const modalRef = this.modalService.open(SuppressionComponent);
    modalRef.componentInstance.patient = patient;
    modalRef.componentInstance.name = "patient";
    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.removePatient(patient);
      }
    }, (reason) => {
      // Handle dismiss case
    });
  }
  //supprimer un patient
  removePatient(patient: any) {
    var medecinId = this.medecinId;
    if (patient && patient.listMedecinsId && patient.listMedecinsId.length > 0) {
      patient.listMedecinsId.splice(patient.listMedecinsId.indexOf(medecinId), 1);
    }
    this.patients.splice(this.patients.indexOf(patient), 1);
    this.patientService.editlistMedecinsPatient(patient).subscribe(() => {
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    }, error => {
      console.log('Error removing patient:', error);
    });
  }
  updatePatient(id) {
    this.router.navigate(['/medecin/patient', id]);
  }
  selectMotif() {
    let modalRef
    modalRef = this.modalService.open(MotifConsultationComponent);
  }

  ViewHistory() {
    let modalRef
    modalRef = this.modalService.open(HistoriqueConsultationComponent);
  }

  filter() {
    this.patients = this.copyListPatients;
    let filteredPatients = [...this.copyListPatients];

    // Convert filter criteria to lowercase
    const numFicheilterLower = this.numFicheToFiltred ? this.numFicheToFiltred.toLowerCase() : '';
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const professionFilterLower = this.professionToFiltred ? this.professionToFiltred.toLowerCase() : '';
    const ageFilterLower = this.ageToFiltred ? this.ageToFiltred.toLowerCase() : '';
    // const emailFilterLower = this.emailToFiltred ? this.emailToFiltred.toLowerCase() : '';
    // const telephoneFilterLower = this.telephoneToFiltred ? this.telephoneToFiltred : '';

    filteredPatients = this.patients.filter(item => {
      const numFicheFilter = numFicheilterLower ? (item.numFiche ? item.numFiche.toLowerCase().includes(numFicheilterLower) : false) : true;
      const nomFilter = nomFilterLower ? (item.nom ? item.nom.toLowerCase().includes(nomFilterLower) : false) : true;
      const prenomFilter = prenomFilterLower ? (item.prenom ? item.prenom.toLowerCase().includes(prenomFilterLower) : false) : true;
      const professionFilter = professionFilterLower ? (item.profession ? item.profession.toLowerCase().includes(professionFilterLower) : false) : true;
      const ageFilter = ageFilterLower ? ((item.age ? item.age.toLowerCase() : '').replace(/\s/g, '')).includes(ageFilterLower) : true;

      return numFicheFilter && nomFilter && prenomFilter && professionFilter && ageFilter
    });

    this.patients = filteredPatients;

    // Reset pagination
    this.page = 1;
  }
  resetFilters() {
    this.nomToFiltred = "";
    this.prenomToFiltred = "";
    this.numFicheToFiltred = "";
    this.professionToFiltred = "";
    this.ageToFiltred = "";

    this.filter();
  }
  searchPatientWithID() {
    let modalRef
    modalRef = this.modalService.open(PopupSearchPatientComponent);
    modalRef.componentInstance.medecinUsedId = this.medecinUsedId;
  }

  generateEXCELpatient() {
    if (this.medecinUsedId) {
      var currentDate = new Date()
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()

      this.medecinService.getAllpatientsPDFExcel(this.medecinUsedId).subscribe((data: any) => {
        var readyToExport = data
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet(readyToExport);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        XLSX.writeFile(workBook, 'Listes_patients_' + year + month + day + '.xlsx'); // initiate a file download in browser
      }, err => {
        return false;
      });
    } else {
      this.iziToast.show({
        message: 'Erreur !.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
  }
  triapatients(a, b) {
    if (a.created_at > b.created_at) return -1;
    else if (a.created_at == b.created_at) return 0;
    else return 1;
  }


}
